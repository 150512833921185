const user_scopes = {
    descriptions: {
        custom_data: 'Vos données personnalisées',
        email: 'Votre adresse e-mail',
        phone: 'Votre numéro de téléphone',
        profile: "Votre nom, nom d'utilisateur, avatar et autres informations de profil",
        roles: 'Vos rôles',
        identities: 'Vos identités sociales liées',
        'urn:logto:scope:organizations': 'Les informations de vos organisations',
        'urn:logto:scope:organization_roles': "Vos rôles dans l'organisation",
        address: 'Votre adresse',
    },
};
export default Object.freeze(user_scopes);
