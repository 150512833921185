const action = {
    sign_in: 'Connexion',
    continue: 'Continuer',
    continue_as: 'Continuer en tant que {{name}}',
    create_account: 'Créer un compte',
    create_account_without_linking: 'Créer un nouveau à la place',
    create: 'Créer',
    enter_passcode: 'Entrer le code',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    save_password: 'Enregistrer le mot de passe',
    bind: 'Lier avec {{address}}',
    bind_and_continue: 'Lier et continuer',
    back: 'Aller en arrière',
    nav_back: 'Retour',
    nav_skip: 'Passer',
    agree: 'Accepter',
    got_it: 'Compris',
    sign_in_with: 'Continuer avec {{name}}',
    forgot_password: 'Mot de passe oublié?',
    switch_to: 'Passer au {{method}}',
    sign_in_via_passcode: 'Se connecter avec le code de vérification',
    sign_in_via_password: 'Se connecter avec le mot de passe',
    change: 'Changer {{method}}',
    link_another_email: 'Lier une autre adresse e-mail',
    link_another_phone: 'Lier un autre numéro de téléphone',
    link_another_email_or_phone: 'Lier une autre adresse e-mail ou un autre numéro de téléphone',
    show_password: 'Afficher le mot de passe',
    copy: 'Copier',
    verify_via_passkey: "Vérifier via la clé d'accès",
    download: 'Télécharger',
    single_sign_on: 'Connexion unique',
    authorize: 'Autoriser',
    use_another_account: 'Utiliser un autre compte',
    back_to_current_account: 'Retour au compte actuel',
};
export default Object.freeze(action);
